/* Video Contact Button */
.video-btn{
    cursor: pointer;
    display: inline-block;
    color: rgb(255, 255, 255);
    padding: 5px;
    border: solid 1px rgb(255, 255, 255);
    font-weight: 500;
    transition: background-color .3s, color .3s;
}
.video-btn:hover{
    background-color: rgba(255, 255, 255, 0.459);
    color: black;
}
.btn-video-container {
    text-align: center;
    position: absolute;
    width: 100%;
    z-index: 1;
    margin-top: 40vw;
}



    @media (min-width: 650px) {
        .btn-video-container {
            margin-top: 0;
        }

        .video-btn {
            margin-top: 150px;
            font-size: 18px;
            padding: 15px 30px;
            border: solid 2px rgb(255, 255, 255);
        }
    }
