form {
    margin-bottom: 20px;
    font-size: 18px;
    font-family: 'Literata', serif;
    font-weight: 300;
}


.contact-header {
    text-align: center;
    margin: 0px 0 10px 0;
    font-size: 29px;
}


form button {
    cursor: pointer;
    display: block;
    background-color: #f5f5f5;
    font-size: 28px;
    font-family: 'Literata', serif;
    font-weight: 300;
    width: 160px;
    margin: 20px auto;
    border-radius: 3px;
    border: solid 2px grey;
    transition: box-shadow .3s, background-color .3s;
}
    form button:hover {
        box-shadow: 4px 3px 6px #00000036;
        background-color:white;
    }
    form button:active {
        box-shadow: none;
        font-size: 29px;
    }
   

.tell-us{
    height: 85px;
}

.comp-info{
    text-align: center;
    margin-top: 20px;
}
a{
    line-height: 25px;
    color:#292a2c;
}
.success{
    text-align: center;
    padding: 15px;
    height: 400px;
}
.loading{
    text-align: center;
    height: 400px;
}
.optional{
    margin-right: -5px;
    color: #ec7892;
}
.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-container h1 {
    text-align: center;
    font-weight: 500;
}

.contact-input {
    width: 100%;
    min-height: 25px;
    margin-bottom: 15px;
}
input{
    margin-bottom: 15px;
}

option {
    width: 100%;
    text-align-last: center;
}

.overflow{
    overflow:hidden;
}

@media (max-width: 468px) {
    .contact-img {
        top: 155px;
    }
}

.img-container h1{
    font-weight: 400;
    font-size: 30px;
    margin-bottom: 30px;
    text-align: center;
    font-family: 'Open Sans',sans-serif;
}

.img-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow:hidden;
    clear:both;
}
.contact-Letter {
    background: white;
    max-width: 300px;
    margin: 23px auto;
    border: solid 2px;
    padding: 20px 30px;
    border-radius: 3px;
}
.contact-img {
    z-index: -1;
    min-width:900px;
}
.form-container {
    position: absolute;
    width: 100%;
    padding: 0 30px;
}

button.sc-AxjAm.eEcvWu {
    outline: 0;
    border: none;
    -moz-outline-style: none;
}
