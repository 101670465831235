
.home-container {
    margin-top: -50px;
}
.home-info-container{
    margin-top: 30px;
}
.message {
    margin: 50px auto 60px auto;
    max-width: 600px;
    text-align: center;
    padding: 0 20px;
}
@media (max-width: 650px) {
    .message{
        max-width: 400px;
    }
}
@media (max-width: 450px) {
    .message{
        max-width: 300px;
    }
}
.message h1{
    font-size: 30px;
    font-weight: 400;
}
@media (max-width: 650px) {
    .message h1{
        font-size: 20px;
    }
}
.message p{
    color: #8a8a8a;
    margin: 0 auto;
    font-size: 20px;
    font-weight: 300;
}

.img-holder{
    max-width: 500px;
    margin: 0 20px;
}
/* on home-link hover, effect only img-holder */
.button-work:hover img{
 transform: scale(1.01);
}
.button-work:hover{
    box-shadow: 5px 10px 20px #69696957;
}
.button-work{
    transition: box-shadow .3s;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    max-height: 150px;
    overflow: hidden;
    margin: 20px 0 0 0;
}

img{
    transition: opacity .3s, transform .3s;
}

img{
    width: 100%;
    height: 100%;
}

.our-work{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

    .our-work h2 {
        text-align: center;
        position: absolute;
        font-weight: 500;
        height: 100px;
        color: white;
        text-shadow: rgb(0, 0, 0) 1px 0 10px;
        z-index: 1;
        font-size: 40px;
        line-height: 80px;
    }

.stars{
    margin: 10px 0 0 0;
    color: #ff94ac;
}

.contact-holder {
    max-width: 400px;
    margin: 0 auto;
    padding: 0 20px;
}
.contact-holder h1{
    font-family: 'Open Sans',sans-serif;
    text-align: center;
    font-weight: 100;
}


@media (min-width: 650px) {

    .home-demo {
        overflow: hidden;
        max-height: 600px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .home-container {
        margin-top: -5px;
    }
}

    @media (min-width: 450px) {

        .our-work {
            display: flex;
            flex-direction: row;
        }

        .img-holder {
            margin: 0;
        }

        .img-holder {
            max-width: 370px;
        }

        .button-work.one {
            margin: 20px 10px 20px 20px;
        }

        .button-work.two {
            margin: 20px 20px 20px 10px;
        }
    }
