
.portfolio-container h1{
    text-align: center;
    font-weight: 500;
}
.portfolio-container h2{
    padding-top: 10px;
    font-weight: 500;
    text-align: center;
}
.portfolio-container p{
    color: #8a8a8a;
    padding: 0 30px 20px 30px;
    margin: 0 auto;
    font-size: 17px;
}

.portfolio-container{
    clear:both;
    display:flex;
    flex-direction:column;
}

.name {
    margin: 10px 0 !important;
    text-align: center;
}
.teaser-text{
    max-width: 860px;
    text-align: center;
}
.teaser-section {
    background: linear-gradient( 0deg, rgb(255 198 198),#f2e6e6 10%,#fffdfd 90%,rgb(255 212 212));
    padding-top: .1px;
}
.tease-films{
    display: flex;
justify-content: center;
}
.tease{
    width: 400px;
}
