.packages {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 211px;
    max-width: 400px;
    margin: 0 auto;
    padding: 0 30px;
}
.package-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    clear: both;
}

a.package-btn {
    border: 2px solid;
    padding: 7px 30px;
    border-radius: 5px;
    font-weight: 600;
    background: #f2f2f3;
    position: relative;
    top: -80px;
    padding: 8px 31px;
    transition: background-color .3s, box-shadow .3s;
}
    a.package-btn:hover {
        background-color: white;
        box-shadow: 4px 3px 6px #0000004d;
    }
    a.package-btn:active {
        box-shadow: none;
        padding: 7px 30px;
    }

    .package-container h1 {
        text-align: center;
        font-weight: 500;
    }

.contents {
    margin-bottom: 50px;
}
.upgrades {
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(242,230,230,1) 10%, rgba(242,230,230,1) 44%, rgba(255,255,255,1) 100%);
    padding-top: .1px;
}

.line-height{
    padding: 0 10px;
}

.package h2 {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    margin: 10px;
    padding-bottom: 30px;
}

.every-package {
    text-align: center;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 26px;
}

/* Package BOX */
.packages h3 {
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    margin: 10px;
    color: grey;
}

.package {
    width: 100%;
    text-align: center;
    margin: 0 10px;
}

.package h3 {
    position: relative;
}

    .package.one a.package-btn {
        color: #99adc4;
    }
    .package.two a.package-btn {
        color: #ffbb53;
    }
    .package.three a.package-btn {
        color: #2f4d5b;
    }

.packages-btn {
    color: #292a2c;
    padding: 10px 25px;
    font-size: 18px;
    border: solid 2px rgb(0 0 0);
    display: inline-block;
    width: 150px;
    height: 50px;
}
.package-btn-container {
    text-align: center;
    margin: 35px;
    position: relative;
}


.title-bonus {
    margin: 0;
    padding: 0;
}

.contact-Btn-package {
    display: flex;
}
.bonus {
    font-weight: bold;
    padding: 0 0 5px 0;
    margin: 0;
}

.starting-at {
    padding-top: 30px;
}

/**** UPGRADES OPTIONS ****/
.add-ons {
    display: flex;
    flex-direction: column;
}

.add-on {
    display: flex;
    justify-content: center;
    align-items: center;
}


.buttoninContainer {
    display: flex;
}


.sofias-pic {
    width: 100%;
    min-width: 700px;
    height: 100%;
}
.img-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.add-ons-title {
    text-align: center;
    font-weight: 400;
    color: #ec7892;
    font-size: 20px;
    text-transform: uppercase;
}


.icons{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 40px 0;
    font-weight: 600;
}
.row-one{
    display: flex;
    align-items: center;
   padding-right:10px;
}
.row-two{
    display: flex;
    align-items: center;
    padding-left:10px;
}
.icons img{
    width: 50px;
    height: auto;
}
.icon-div{
    height: 150px;
    width: 200px;
}
img.card {
    margin: 10px 10px;
    width: 29%;
    max-width: 250px;
}

@media (max-width: 650px){
    img.card {
        width: auto;
    }
    .add-on{
        flex-direction: column;
    }
.icons{
    justify-content: center;
    flex-direction: row;
    text-align: center;
    margin: 40px 0;
    font-weight: 600;
    font-size: 15px;
}
.row-one{
    display: flex;
    flex-direction: column;
    align-items: center;
   padding-right:10px;
}
.row-two{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left:10px;
}
.icons img{
    width: 50px;
    height: auto;
}
.icon-div{
    height: 130px;
    width: auto;
}
}

@media (min-width: 650px) {
    .packages {
        flex-direction: row;
        max-width: 800px;
    }
}
@media (max-width: 650px) {
    .package {
        padding: 0 20px;
    }
}
