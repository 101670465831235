*, ::after, ::before {
    box-sizing: border-box;
}

body{
    font-family: 'Open Sans',sans-serif;
    color: #292a2c;
    margin: 0;
    padding: 0;
}

.lite{
    font-family: 'Literata', serif;
    font-weight: 300;
}

.container-layout{
    min-height: 800px;
}