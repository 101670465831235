/**** OUR WORK PAGE CSS ****/
.vimeo-container {
    max-width: 500px;
    margin: 0 auto;
}

.light-photo {
    height: 155px;
    max-width: 500px;
    display: inline-block;
    position: relative;
    object-fit: cover;
    cursor: pointer;
}

.playButton {
    cursor: pointer;
    position: absolute;
    color: white;
    z-index: 1;
    font-size: 40px;
    text-shadow: rgb(0, 0, 0) 1px 0 10px;
}

.video-preload {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    max-width: 760px;
    margin: 0 auto;
    transition: box-shadow .3s;
}
.video-preload:hover{
    box-shadow: 5px 10px 20px #69696957;
}

.vimeo-container iframe {
    width: 100%;
    height: 100%;
}

@media (min-width: 350px) {
    .vimeo-container iframe {
        height: 250px;
    }

    .light-photo {
        height: 255px;
    }
}

/**** HOME PAGE CSS ****/

.home-vimeo-container {
    max-width: 800px;
    margin: 0 auto;
}
.video-preload img:hover{
    opacity: .95;
    transform: scale(1.01);
}

.home-light-photo {
    display: inline-block;
    position: relative;
    object-fit: cover;
    cursor: pointer;
}

.home-vimeo-container iframe {
    width: 100%;
    height: 100%;
}

@media (min-width: 310px) {
    .home-vimeo-container iframe {
        height: 200px;
    }
}

@media (min-width: 400px) {
    .home-vimeo-container iframe {
        height: 250px;
    }
}

@media (min-width: 485px) {
    .home-vimeo-container iframe {
        height: 300px;
    }
}

@media (min-width: 575px) {
    .home-vimeo-container iframe {
        height: 350px;
    }
}

@media (min-width: 665px) {
    .home-vimeo-container iframe {
        height: 400px;
    }

}

@media (min-width: 755px) {
    .home-vimeo-container iframe {
        height: 425px;
    }
}
