.quote-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    margin: 20px auto;
    margin: 0 auto 20px auto;
    padding: 0 30px;
}
.quote{
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.quote p{
    margin: 0px;
    padding: 5px;
}
.quote .lite{
    color:#292a2c;
}
.quoter{
    color: grey;
    font-weight: 400;
}
