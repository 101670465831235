
.logo {
    color: #292a2c;
    text-align: center;
    text-decoration: none;
    font-family: 'Old Standard TT', serif;
    display: block;
    max-width: 500px;
    line-height: 35px;
    font-size: 18px;
    margin: 5px auto;
}

    .logo h1 {
        z-index: 999 !important;
        margin-bottom: 20px;
        position: relative;
    }

@media (min-width: 650px) {
    .logo img{
      width: 300px;
    }
}
@media (max-width: 650px) {
    .logo {
        font-size: 16px;
        width: 260px;
    }
        .logo h1 {
            margin-bottom: -38px;
        } 
}

@media (max-width: 350px) {
    .logo {
        width: 191px;
        font-size: 12px;
    }
}
